@import "../lib/colors";

.inline-img {
  width: 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.fac {
  vertical-align: middle;
  color: $color-primary;
}

.fac:hover {
  color: darken($color-primary, 10%);
}

.copy-id-btn:hover {
  color: #858585;
}

.copy-id-btn:active {
  color: black;
}
